<template>
  <div
    class="mbom-impact-test px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <div class="flex flex-wrap">
      <div class="w-full sm:w-4/5">
        <TitleComponent class="py-8" title="ANE Gap Test" size="large" />

        <div class="flex flex-wrap mt-8 p-4" style="background-color:#f7f5f4;">
          <div class="w-full">
            <TitleComponent
              class="py-8"
              title="ANE Gap Test Description"
              size="medium"
            />
            <a
              :href="require('../../assets/UN/ANEGapDetailed-min.png')"
              target="_blank"
            >
              <img class="m-auto" src="@/assets/UN/ANEGapDetailed-min.png" />
            </a>
          </div>
          <div class="w-full">
            <TitleComponent class="py-8" title="Test Purpose" size="medium" />
            <p class="p-light">
              The purpose of the ANE Gap test is to measure the ability of a
              substance to propagate a detonation from defined shock and
              confinement.
            </p>
          </div>
        </div>
        <div class="flex flex-wrap">
          <div class="w-full">
            <TitleComponent class="pt-8" title="Key Parameters" size="medium" />
            <div class="md:px-10 md:pb-10 overflow-x-auto">
              <table class="test-table">
                <tbody>
                  <tr>
                    <th>Key Parameter</th>
                    <th>Objective</th>
                    <th>Origin</th>
                    <th>Specs</th>
                  </tr>
                  <tr>
                    <td>Initiator</td>
                    <td>Initiate the booster</td>
                    <td></td>
                    <td>UN standard detonator or equivalent</td>
                  </tr>
                  <tr>
                    <td>Booster</td>
                    <td>
                      Provide a strong, stable shock front to the top of the
                      sample
                    </td>
                    <td></td>
                    <td>
                      95 mm OD x 95 mm long pressed 50/50 pentolite or 95/5
                      RDX/wax pellet with a density of 1600 kg/m^3 ± 50 kg/m^3.
                    </td>
                  </tr>
                  <tr>
                    <td>Gap</td>
                    <td>Attenuate the shock pressure to near 3.5 – 4 GPa</td>
                    <td></td>
                    <td>95 mm dia x 70 mm long PMMA spacer</td>
                  </tr>
                  <tr>
                    <td>Confining medium (steel tube)</td>
                    <td>
                      Provide confinement, increasing the susceptibility of the
                      substance to detonation
                    </td>
                    <td></td>
                    <td>
                      95 mm OD, 11.1 mm wall thickness, by 280 mm long cold
                      drawn steel tube with tensile strength of 420 MPa, %
                      elongation of 22, and Brinell hardness of 125.
                    </td>
                  </tr>
                  <tr>
                    <td>Cardboard tube</td>
                    <td>Ensure test components are coaxially aligned</td>
                    <td></td>
                    <td>97 mm ID x 443 mm long</td>
                  </tr>
                  <tr>
                    <td>Witness plate specifications</td>
                    <td>Evidence of reaction type</td>
                    <td></td>
                    <td>
                      200 x 200 x 20 mm mild steel witness plate with tensile
                      strength of 580 MPa, % elongation of 21, and Brinell
                      hardness of 160.
                    </td>
                  </tr>
                  <tr>
                    <td>Space below witness plate</td>
                    <td>
                      Space below the witness plate to not impede plate puncture
                    </td>
                    <td></td>
                    <td>Sufficient space</td>
                  </tr>
                  <tr>
                    <td>Number of trials</td>
                    <td>
                      Sufficient trials to enable unambiguous interpretation of
                      the results
                    </td>
                    <td></td>
                    <td>3 trials</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <TitleComponent class="pt-8" title="Indicators" size="medium" />
            <div class="md:px-10 md:pb-10 overflow-x-auto">
              <table class="test-table">
                <tbody>
                  <tr>
                    <th>Indicators</th>
                    <th>Detection Method</th>
                    <th>Assessment*</th>
                  </tr>
                  <tr>
                    <td>Damage to the witness plate</td>
                    <td>Visual post-test inspection</td>
                    <td>Clean hole punched through the witness plate</td>
                  </tr>
                </tbody>
              </table>
              <p class="p-light">
                *If a substance detonates in any trial at a gap length of 70 mm,
                it is not to be classed as “ammonium nitrate emulsion or
                suspension or gel, intermediate for blasting explosives”.
              </p>
            </div>
          </div>
        </div>
        <div class="flex flex-wrap pb-6" style="background-color:#f7f5f4;">
          <div class="w-full md:w-1/2 p-4">
            <TitleComponent
              class="pt-8"
              title="Example Photo of Not Holed Witness Plate (-)"
              size="medium"
            />
            <a
              :href="require('../../assets/UN/ane-nogo-min.jpg')"
              target="_blank"
            >
              <img class="m-auto" src="@/assets/UN/ane-nogo-min.jpg" />
            </a>
          </div>
          <div class="w-full md:w-1/2 p-4">
            <TitleComponent
              class="pt-8"
              title="Example Video of Not Holed Witness Plate (-)"
              size="medium"
            />
            <iframe
              class="m-auto"
              src="https://player.vimeo.com/video/165948431?autoplay=0&amp;autopause=0"
              allowfullscreen=""
              title="vimeo165948431"
              allow="autoplay; fullscreen"
              id="player_1"
              name="fitvid0"
            ></iframe>
          </div>
        </div>
      </div>
      <SideBar class="w-full sm:w-1/5" />
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import SideBar from "@/components/UnSideBar";

export default {
  components: {
    TitleComponent,
    SideBar
  }
};
</script>

<style lang="scss"></style>
